<template>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <!--begin::Card header-->
    <div class="card-header cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">{{ translate("changeYourPassword") }}</h3>
      </div>
      <!--end::Card title-->

      <!--begin::Action-->
      <button
        v-if="!isPasswordFormVisible"
        class="btn btn-primary align-self-center"
        @click="startPasswordChange"
      >
        {{ translate("passwordChange") }}
      </button>
      <!--end::Action-->
    </div>
    <!--begin::Card header-->

    <!--begin::Card body-->
    <div v-if="isPasswordFormVisible" class="card-body p-9">
      <!-- begin:: Password Form -->
      <div class="password-form-holder">
        <PasswordForm
          @onSave="saveNewPassword"
          @onCancel="endPasswordChange()"
        />
      </div>
      <!-- end:: Password Form -->

      <div
        v-if="false"
        class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mt-12"
      >
        <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
          <inline-svg src="media/icons/duotone/Code/Warning-1-circle.svg" />
        </span>
        <!--begin::Wrapper-->
        <div class="d-flex flex-stack flex-grow-1 ">
          <!--begin::Content-->
          <div class=" fw-bold">
            <h4 class="text-gray-800 fw-bolder">
              {{ translate("passwordRemember") }}
            </h4>

            <div class="fs-6 text-gray-600 ">
              {{ translate("passwordValidationDuration") }}
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Wrapper-->
      </div>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::details View-->
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import JwtService from "@/core/services/JwtService";
import PasswordForm from "@/components/app/Account/PasswordForm.vue";

export default defineComponent({
  name: "account-overview",
  components: {
    PasswordForm
  },
  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Cambio de contraseña", ["Configuración"]);
    });

    return {
      translate
    };
  },
  data() {
    return {
      waitingMessage: "",
      isPasswordFormVisible: false
    };
  },
  methods: {
    startPasswordChange() {
      this.isPasswordFormVisible = true;
    },
    saveNewPassword(password) {
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/users/change-password`;

      const currentToken = JwtService.getToken();
      const body = {
        token: currentToken,
        password: password
      };
      this.waitingMessage = "Guardando nueva contraseña...";
      fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(body)
      })
        .then(() => {
          this.emitter.emit("load-organizations");
          this.$notify({
            title: "Genial",
            message: "La contraseña se ha guardado exitosamente",
            type: "success"
          });
        })
        .catch(error => {
          this.$notify({
            title: "Bad",
            message: this.translate("generalError"),
            type: "danger"
          });
        })
        .finally(() => {
          this.waitingMessage = "";
          this.endPasswordChange();
        });
    },
    endPasswordChange() {
      this.isPasswordFormVisible = false;
    }
  }
});
</script>
