<template>
  <Form
    id="kt_modal_password_form"
    class="form"
    @submit="save"
    :validation-schema="changePassword"
  >
    <!--begin::Input group-->
    <div class="row mb-10">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col-->
          <div class="col-6">
            <!--begin::Label-->
            <label class="required fs-6 fw-bold form-label mb-2"
              >Contraseña</label
            >
            <!--end::Label-->
            <Field
              type="password"
              name="password"
              class="form-control form-control-solid"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="password" />
              </div>
            </div>
          </div>
          <!--end::Col-->

          <!--begin::Col-->
          <div class="col-6">
            <!--begin::Label-->
            <label class="required fs-6 fw-bold form-label mb-2"
              >Confirma contraseña</label
            >
            <!--end::Label-->
            <Field
              type="password"
              name="passwordConfirmation"
              class="form-control form-control-solid"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="passwordConfirmation" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

    <!--begin::Actions-->
    <div class="mt-3 text-center">
      <button
        ref="cancelButtonRef"
        type="button"
        id="cancel_button"
        class="btn btn-light me-3"
        @click="$emit('onCancel')"
      >
        <span class="indicator-label">
          Cancelar
        </span>
      </button>

      <button
        ref="submitButtonRef"
        type="submit"
        id="submit_button"
        class="btn btn-primary"
      >
        <span class="indicator-label">
          Guardar
        </span>
        <span class="indicator-progress">
          Please wait...
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
      </button>
    </div>
    <!--end::Actions-->
  </Form>
</template>

<script>
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    ErrorMessage,
    Field,
    Form
  },
  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const changePassword = Yup.object().shape({
      password: Yup.string()
        .minUppercase(
          1,
          translate("password") + " " + translate("minUppercase")
        )
        .minNumbers(1, translate("password") + " " + translate("minNumbers"))
        .minSymbols(1, translate("password") + " " + translate("minSymbols"))
        .min(8, translate("password") + " " + translate("min"))
        .required(translate("password") + " " + translate("isRequired")),
      passwordConfirmation: Yup.string()
        .required(translate("passwordConfirm") + " " + translate("isRequired"))
        .oneOf([Yup.ref("password"), null], translate("PasswordsMustMatchYup"))
    });

    return {
      translate,
      changePassword
    };
  },
  methods: {
    save(values) {
      this.$emit("onSave", values.password);
    }
  }
});
</script>
